<template>
  <a-layout-header class="layout-header">
    <a-row>
      <a-col :span="24">
        <a-button
          class="header-menu-btn"
          type="link" shape="circle"
          @click="appStore.collapsed=!appStore.collapsed"
        >
          <template #icon>
            <span class="mdi mdi-menu" :style="{ color: '#212121', fontSize: '1.5rem' }"></span>
          </template>
        </a-button>
        
        <div class="header-title">個人資料</div>
      </a-col>
    </a-row>
  </a-layout-header>

  <a-layout-content class="layout-content">
    <div class="me-page__wrapper">
      <a-card
        class="me-info-card"
        :bordered="false"
        v-if="!loading && Object.keys(memberData).length"
      >
        <div class="card-title">基本資料</div>

        <a-form
          :model="memberData"
          layout="vertical"
          autocomplete="off"
        >
          <a-row :gutter="24">
            <a-col :lg="12" :xs="24">
              <a-form-item
                label="會員編號"
                name="member_id"
              >
                <a-input v-model:value="memberData.member_id" readonly :bordered="false" size="large" />
              </a-form-item>

              <a-form-item
                label="姓名"
                name="name"
                :rules="[{ required: true, message: '請輸入姓名' }]"
              >
                <a-input v-model:value="memberData.name" size="large" />
              </a-form-item>
            </a-col>

            <a-col :lg="12" :xs="24">
              <a-form-item
                label="大頭貼"
                name="avatar"
              >
                <a-upload
                  v-model:file-list="avatarFileList"
                  class="image-uploader"
                  list-type="picture-card"
                  :show-upload-list="false"
                  action="/api/v1/file"
                  :before-upload="beforeAvatarUpload"
                  @change="handleAvatarChange"
                >
                  <img v-if="previewImageUrl" :src="previewImageUrl" alt="avatar" style="width:100%;" />
                  <div v-else>
                    <loading-outlined v-if="avatarUploading"></loading-outlined>
                    <plus-outlined v-else></plus-outlined>
                    <div class="ant-upload-text">選擇圖片</div>
                  </div>
                </a-upload>
              </a-form-item>
            </a-col>
          </a-row>

          <!-- Role: Asssitant & Coach -->
          <template v-if="authStore.info.role == 3 || authStore.info.role == 2">
            <a-row :gutter="24">
              <a-col :lg="12" :xs="24">
                <a-form-item
                  label="性別"
                  name="gender"
                  :rules="[{ required: true, message: '請選擇性別' }]"
                >
                  <a-select
                    v-model:value="memberData.gender"
                    :options="genderOptions"
                    size="large"
                  ></a-select>
                </a-form-item>
              </a-col>

              <a-col :lg="12" :xs="24">
                <a-form-item
                  label="生日"
                  name="birthdate"
                  :rules="[{ required: true, message: '請輸入生日' }]"
                >
                  <a-date-picker
                    v-model:value="memberData.birthdate"
                    :show-time="{ format: 'YYYY-MM-DD' }"
                    format="YYYY-MM-DD"
                    size="large"
                    :style="{ width: '100%' }"
                  />
                </a-form-item>
              </a-col>
            </a-row>

            <a-row :gutter="24">
              <a-col :lg="12" :xs="24">
                <a-form-item
                  label="LINE ID"
                  name="line_friend_id"
                  :rules="[{ required: true, message: '請輸入會員的 LINE Id' }]"
                >
                  <a-input v-model:value="memberData.line_friend_id" size="large" />
                </a-form-item>
              </a-col>

              <a-col :lg="12" :xs="24">
                <a-form-item
                  label="聯絡電話"
                  name="phone"
                  :rules="[{ required: true, message: '請輸入聯絡電話' }]"
                >
                  <a-input v-model:value="memberData.phone" size="large" />
                </a-form-item>
              </a-col>
            </a-row>

            <a-row>
              <a-col :span="24">
                <a-form-item
                  label="聯絡地址"
                  name="address"
                  :rules="[{ required: true, message: '請輸入聯絡地址' }]"
                >
                  <a-input v-model:value="memberData.address" size="large" />
                </a-form-item>
              </a-col>
            </a-row>
          </template>
          <!-- Role: Asssitant & Coach -->
        </a-form>

        <template #actions>
          <a-button type="primary" @click="save">更新儲存</a-button>
        </template>
      </a-card>

      <a-card
        class="me-info-card"
        :bordered="false"
      >
        <div class="card-title">進階功能</div>

        <div class="card-body">
          <a-row :gutter="48">
            <a-col :lg="6" :xs="24">
              <div class="body-title" align="right">密碼管理</div>
            </a-col>

            <a-col :lg="18" :xs="24">
              <a-form
                :model="changePasswordFormState"
                layout="vertical"
                autocomplete="off"
              >
                <a-row :gutter="20">
                  <a-col :lg="12" :xs="24">
                    <a-form-item
                      label="舊密碼"
                      name="oldPassword"
                      :rules="[{ required: true, message: '請輸入舊密碼' }]"
                    >
                      <a-input-password v-model:value="changePasswordFormState.oldPassword" size="large" />
                    </a-form-item>
                  </a-col>
                  <a-col :lg="12" :xs="24">
                    <a-form-item
                      label="新密碼"
                      name="newPassword"
                      :rules="[{ required: true, message: '請輸入新密碼' }]"
                    >
                      <a-input-password v-model:value="changePasswordFormState.newPassword" size="large" />
                    </a-form-item>
                  </a-col>
                </a-row>
              </a-form>
            </a-col>

            <a-col :lg="24" :xs="24" align="right">
              <a-button type="primary" @click="changePassword">修改密碼</a-button>
            </a-col>
          </a-row>
        </div>
      </a-card>
    </div>
  </a-layout-content>
</template>

<script>
import { defineComponent, ref, onMounted, onUnmounted } from 'vue'
import { message, Upload } from 'ant-design-vue'
import { useAuthStore } from '@/store/auth'
import { useAppStore } from '@/store/app'
import dayjs from 'dayjs'
import api from '@/apis'
import schema from '@/schemas'

export default defineComponent({
  components: {},
  setup() {
    /* Store */
    const authStore = useAuthStore();
    const appStore = useAppStore();

    /* Loading */
    const loading = ref(false);

    /* Data */
    const memberData = ref({});

    /* Form State */
    // change password
    const changePasswordFormState = ref({
      oldPassword: '',
      newPassword: ''
    });

    /* Avatar Uploader */
    const avatarFileList = ref([]);
    const avatarUploading = ref(false);
    const previewImageUrl = ref('');
    const currentImageCode = ref('');

    function _getBase64(img, callback) {
      const reader = new FileReader();
      reader.addEventListener('load', () => callback(reader.result));
      reader.readAsDataURL(img);
    }

    const handleAvatarChange = async info => {
      console.log(info);

      if (info.file.status === 'uploading') {
        avatarUploading.value = true;
        return
      }

      if (info.file.status === 'done') {
        _getBase64(info.file.originFileObj, base64Url => {
          previewImageUrl.value = base64Url;
          avatarUploading.value = false;
        });
        
        memberData.value.avatar = {
          name: info.file.name,
          uri: info.file.response.result.file_src,
          size: info.file.size
        };
      }

      if (info.file.status === 'error') {
        avatarUploading.value = false;
        message.error('大頭貼照片上傳失敗，請稍後再試');
      }
    }

    const beforeAvatarUpload = file => {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
      if (!isJpgOrPng) {
        message.error('請選擇 jpg 或 png 的圖片格式');
      }

      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        message.error('圖片大小不可以超過 2MB');
      }

      return (isJpgOrPng && isLt2M) || Upload.LIST_IGNORE;
    }

    /* Api */
    const getMemberData = (async () => {
      loading.value = true;

      // Admin
      if (authStore.info.role == 4) {
        const response = await api.v1.admin.findOne(authStore.info.uid);
        memberData.value = { ...response.data.result };
        console.log(response.data.result);
      }

      // Assistant
      else if (authStore.info.role == 3) {
        const response = await api.v1.assistant.findOne(authStore.info.uid);
        memberData.value = { ...response.data.result };
        console.log(response.data.result);
      }

      // Coach
      else if (authStore.info.role == 2) {
        const response = await api.v1.coach.findOne(authStore.info.uid);
        memberData.value = { ...response.data.result };
        console.log(response.data.result);
      }

      // 紀錄目前的 image code
      if (memberData.value.avatar) {
        currentImageCode.value = memberData.value.avatar.uri;
      }

      // birthdate format
      if (memberData.value.birthdate) {
        memberData.value.birthdate = dayjs(memberData.value.birthdate, 'YYYY-MM-DD');
      }

      loading.value = false;
    });

    const updateMemberData = (async (uid, data) => {
      // Admin
      if (authStore.info.role == 4) {
        try {
          await api.v1.admin.updateOne(uid, data);
          message.success('更新資料成功');
        } catch (error) {
          message.error('更新資料失敗，請稍後再試');
        }
      }

      // Assistant
      else if (authStore.info.role == 3) {
        try {
          await api.v1.assistant.updateOne(uid, data);
          message.success('更新資料成功');
        } catch (error) {
          message.error('更新資料失敗，請稍後再試');
        }
      }

      // Coach
      else if (authStore.info.role == 2) {
        try {
          await api.v1.coach.updateOne(uid, data);
          message.success('更新資料成功');
        } catch (error) {
          message.error('更新資料失敗，請稍後再試');
        }
      }

      // 如果有新增圖片: 紀錄現在的 avatar & 刪除原始的 avatar
      if (avatarFileList.value.length && currentImageCode.value) {
        console.log(currentImageCode.value)
        deleteFile(currentImageCode.value);
        avatarFileList.value = avatarFileList.value.filter(item => item.response.result.file_src!=currentImageCode.value);
        currentImageCode.value = memberData.value.avatar.uri;
      }

      // 更新全局用戶資料
      authStore.info = { ...memberData.value };
    });

    const deleteFile = (async (code) => {
      try {
        const codeSplitList = code.split('/');
        const key = codeSplitList[codeSplitList.length-1];
        await api.v1.file.deleteOne({ key: key });
        console.log(`Delete file success. File code: ${code}`);
      } catch (error) {
        console.log(`Delete file ${code} failed. Error message: ${error}`);
      }
    });

    /* Mounted */
    onMounted(async () => {
      await getMemberData();

      // avatar preview
      if (memberData.value.avatar) {
        previewImageUrl.value = memberData.value.avatar.uri;
      }
    });

    /* Unmounted */
    onUnmounted(_ => {
      // 刪除沒用到的 avatar
      while (avatarFileList.value.length) {
        const item = avatarFileList.value.shift();
        if (item.response.result.file_src != currentImageCode.value) {
          deleteFile(item.response.result.file_src);
        }
      }
    });

    return {
      api,
      schema,

      /* Store */
      authStore,
      appStore,

      /* Options */
      genderOptions: ref([{
        value: 'male',
        label: '先生'
      }, {
        value: 'female',
        label: '女士'
      }]),

      /* Loading */
      loading,

      /* Data */
      memberData,

      /* Form State */
      // change password
      changePasswordFormState,

      /* Avatar Uploader */
      avatarFileList,
      avatarUploading,
      previewImageUrl,
      handleAvatarChange,
      beforeAvatarUpload,

      /* Api */
      updateMemberData,

      /* Antd */
      message
    }
  },
  methods: {
    /* Api */
    async save() {
      const uid = this.memberData.uid;
      let updatedData = {};

      // Admin
      if (this.memberData.role == 4) {
        Object.keys(schema.v1.admin.updateAdmin).forEach(key => {
          updatedData[key] = this.memberData[key];
        });
      }

      // Assistant
      else if (this.memberData.role == 3) {
        Object.keys(schema.v1.assistant.updateAssistant).forEach(key => {
          updatedData[key] = this.memberData[key];
        });
      }

      // Coach
      else if (this.memberData.role == 2) {
        Object.keys(schema.v1.coach.updateCoach).forEach(key => {
          updatedData[key] = this.memberData[key];
        });
      }

      // 處理 birthdate format
      if (updatedData.birthdate) {
        updatedData.birthdate = dayjs(updatedData.birthdate).format('YYYY-MM-DD');
      }

      console.log(updatedData);

      await this.updateMemberData(uid, updatedData);
      // await this.getAssistantsData();
    },
    async changePassword() {
      try {
        await this.api.v1.auth.changePassword({
          old_pass: this.changePasswordFormState.oldPassword,
          new_pass: this.changePasswordFormState.newPassword
        });
        this.message.success('修改密碼成功');
      } catch (error) {
        console.log(error);

        if (error.response.status === 400) {
          this.message.error('您的舊密碼輸入錯誤，請檢查後再試');
        } else {
          this.message.error('修改密碼發生錯誤，請稍後再試');
        }
      } finally {
        this.changePasswordFormState = {
          oldPassword: '',
          newPassword: ''
        }
      }
    }
  }
})
</script>

<style lang="scss">
.me-page__wrapper {
  width: 100%;
  max-width: 760px;
  margin: 16px auto;

  .me-info-card {
    margin-bottom: 28px;

    .card-title {
      font-size: 1.45rem;
      font-weight: 500;
      margin-bottom: 32px;
    }

    .card-body {
      .body-title {
        color: #424242;
        font-size: 1.1rem;
        letter-spacing: 0.0125rem;
      }
    }

    .ant-card-actions {
      padding: 0 24px;

      li {
        display: flex;
        justify-content: right;
      }
    }
  }
}
</style>